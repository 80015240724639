import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [analysisOption, setAnalysisOption] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedBytes, setUploadedBytes] = useState(0);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleAnalysisOptionChange = (event) => {
    setAnalysisOption(event.target.value);
  };

  const handleSyncSubmit = async () => {
    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);
      await submitOptionsAndUpload();
    } catch (error) {
      console.error("Error submitting the videos:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadVideo = async (uploadUrl) => {
    if (isUploading) {
      // Should not be necessary, but just in case
      // It make it more explicit that uploading operation
      // are mutually exclusive
      return;
    }

    try {
      setIsUploading(true);
      await uploadVideo(uploadUrl);
    } catch (error) {
      console.error("Error uploading video:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const submitOptionsAndUpload = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      alert("No auth token found");
      return;
    }

    if (!selectedFile || !analysisOption) {
      alert("Please select a file and an analysis option");
      return;
    }

    const fileName = selectedFile.name;

    // Regular expression to match invalid characters
    // (anything except alphanumbers, underscores, hyphens, and periods)
    const invalidCharRegex = /[^a-zA-Z0-9._-]/;

    if (invalidCharRegex.test(fileName)) {
      alert(
        "File name contains invalid characters. Please rename the file without any whitespaces or special characters."
      );
      return;
    }

    const videoName = fileName.split(".")[0];
    localStorage.setItem("video_name", videoName);
    const analysis = analysisOption;

    try {
      const url = new URL("/nonprod/analyze/async/", window.location.href);

      url.searchParams.append("file-name", fileName);
      url.searchParams.append("analysis", analysis);

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Cache-Control": "max-age=0, must-revalidate, no-cache, no-store",
          Pragma: "no-cache", // For older HTTP/1.0 servers
          Expires: "0", // Ensures the request is always fresh
        },
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Received job ID and upload URL:", data);
        localStorage.setItem("job_id", data.job_id);
        localStorage.setItem("upload_url", data.upload_url);

        await handleUploadVideo(data.upload_url);
      } else {
        alert(`Error: ${data.message}`);
        return;
      }
    } catch (error) {
      console.error("Error starting analysis:", error);
      alert("Error starting analysis: " + error.message);
      return;
    }
  };

  const uploadVideo = async (uploadUrl) => {
    if (!uploadUrl) {
      alert("Upload URL not found.");
      return;
    }

    if (!selectedFile) {
      alert("Please select a file to upload");
      return;
    }

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", uploadUrl, true);
      xhr.setRequestHeader("Content-Type", "");
      xhr.setRequestHeader("Content-Length", selectedFile.size.toString());

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const uploaded = event.loaded;
          const progress = Math.round((uploaded / selectedFile.size) * 100);
          console.log(`Uploaded ${uploaded} bytes (${progress}% completed)`);
          setUploadProgress(progress);
          setUploadedBytes(uploaded);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          console.log("Video uploaded successfully!");
          setShowSuccessPopup(true);
        } else {
          console.error("Error response from server:", xhr.statusText);
          alert(`Error uploading video: ${xhr.statusText}`);
        }
      };

      xhr.onerror = () => {
        console.error("Error uploading video:", xhr.statusText);
        alert(`Error uploading video: ${xhr.statusText}`);
      };

      xhr.send(selectedFile);
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Error uploading video: " + error.message);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragActive(false);
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const goToResults = () => {
    navigate("/results");
  };

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <div>
      <Navbar />
      <div className="upload-container">
        <h1>
          <span className="yellow-text">1. Upload </span> my video to be
          analyzed.
        </h1>
        <div
          className={`upload-box ${isDragActive ? "drag-active" : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <p className="drop-text">Drag & Drop here</p>
          <p className="drop-text">or</p>
          <input
            type="file"
            style={{ display: "none" }}
            id="fileUpload"
            accept="video/*"
            onChange={handleFileChange}
          />
          <label htmlFor="fileUpload" className="upload-button">
            Select file
          </label>
          {selectedFile && <p>Selected file: {selectedFile.name}</p>}
        </div>
        <div className="options-container">
          <h1>
            <span className="yellow-text">2. Select </span> my analysis option.
          </h1>
          <div className="option">
            {/* <label htmlFor="analysisOption">Analysis Option:</label> */}
            <select
              className="optionbar"
              id="analysisOption"
              name="analysisOption"
              value={analysisOption}
              onChange={handleAnalysisOptionChange}
            >
              <option value="" disabled>
                Select Analysis
              </option>
              <option value="Insight,Rugby">Insight</option>
              <option value="Lineout">Lineout</option>
              {/* <option value="Full Game">Full Game</option>
              <option value="Lineouts Tagging">Lineouts Tagging</option>
              <option value="Tracking Data">Tracking Data</option>
              <option value="Other">Other</option> */}
            </select>
          </div>
        </div>
        <button
          className="submit-button"
          onClick={handleSyncSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Uploading..." : "Analyze !"}
        </button>
        {isUploading && (
          <p className="white-text">
            Uploading... {formatBytes(uploadedBytes)} /{" "}
            {formatBytes(selectedFile.size)}
          </p>
        )}
      </div>
      <div className="footer-container">
        <div className="footer-right">
          <a onClick={goToResults} className="nav-link">
            Go to my data record
          </a>
        </div>
      </div>
      {showSuccessPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Upload successful</h2>
            <p>You will be redirected to the results page !</p>
            <button onClick={() => navigate("/results")}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Upload;
